/* Header */
header {
  width: 100%;
  height: 77px;
}

header a.logo {
  position: absolute;
  width: 160px;
  height: 60px;
  top: 0;
  left: 50%;
  margin-left: -80px;
  background: url(../media/images/Area_M_Logo.svg) no-repeat 0 0;
  background-size: 160px 60px;
  overflow: hidden;
  text-indent: -999999em;
  z-index: 110;
}

header #hamburger {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background-color: $redColor;
  display: block;
  cursor: pointer;
  pointer-events: auto;
  z-index: 9995;
  transition: background-color 0.5s ease;
  transition-delay: 0.3s;
}

body.menu-active header #hamburger {
  position: fixed;
  background-color: rgba(226, 0, 26, 0);
}

header #hamburger .hamburger-holder {
  position: absolute;
  top: 15px;
  right: 12px;
}

header #hamburger.active::after {
  opacity: 0;
}

#hamburger span,
#hamburger span::before,
#hamburger span::after {
  background-color: #fff;
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.3s ease;
  width: 25px;
  border-radius: 5px;
}

#hamburger span::after {
  top: 16px;
  width: 20px;
}

#hamburger span::before {
  top: 8px;
  width: 15px;
}

#hamburger:hover span,
#hamburger:hover span::before,
#hamburger:hover span::after {
  width: 25px;
  background-color: #fff;
}

#hamburger.active span {
  background-color: transparent;
}

#hamburger.active span::after {
  transform: rotate(45deg);
  top: 9px;
  width: 25px;
}

#hamburger.active span::before {
  transform: rotate(-45deg);
  top: 9px;
  width: 25px;
}

#menu-holder {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9993;
  background-color: rgba(226, 0, 26, 0.95);
  overflow: hidden;
  transform: translate(100%, -100%);
  transition: all 0.3s ease;
  transition-delay: 0.3s;
}

#menu-holder.menu-active {
  transform: translate(0, 0);
  transition-delay: 0.3s;
}

#menu-holder .wonen-zuid {
  position: absolute;
  width: 50px;
  height: 28px;
  bottom: 20px;
  right: 50%;
  margin-right: -25px;
  background: url(../media/images/wonen-zuid.svg) no-repeat 0 0;
  background-size: 50px 28px;
  overflow: hidden;
  text-indent: -999999em;
  opacity: 0;
  transition: all 0.2s ease;
  transition-delay: 0s;
}

#menu-holder.menu-active .wonen-zuid {
  opacity: 1;
  transition: all 0.5 ease;
  transition-delay: 0.8s;
}

#menu-holder.menu-active .secondary-navigation {
  opacity: 1;
  transition: opacity 0.5 ease;
  transition-delay: 0.8s;
}

#menu-holder nav {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-60%);
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  opacity: 0;
  transition: all 0.2s ease;
  transition-delay: 0s;
  margin-top: -40px;

  @media only screen and (min-width: 768px) {
    margin-top: -30px;
  }
}

#menu-holder.menu-active nav {
  transform: translateY(-50%);
  opacity: 1;
  transition: all 0.5s ease;
  transition-delay: 0.6s;
}

nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 10px;
}

nav ul li a,
.secondary-navigation li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.2s ease;
  display: block;
}

nav ul li a.active,
nav ul li a:hover {
  color: $darkColor;
}

nav ul li:last-of-type {
  margin-bottom: 0;
}

nav ul li ul {
  display: none;
}

nav ul li ul li,
.secondary-navigation li {
  font-size: 16px;
  font-weight: 500;
  line-height: 14px;
  display: inline-block;
  margin-top: 35px;
  margin-bottom: 0;
  float: left;
}

nav ul li ul li a,
.secondary-navigation li a {
  padding: 0 15px;
}

nav ul li ul li::after,
.secondary-navigation li::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 14px;
  background-color: $darkColor;
  display: block;
  margin-top: -15px;
}

nav ul li ul li:first-of-type::after,
.secondary-navigation li:first-of-type::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  display: none;
}

.secondary-navigation {
  position: absolute;
  padding: 0;
  text-transform: uppercase;
  text-align: center;
  bottom: 55px;
  width: 100%;
  opacity: 0;
  transition: opacity 0.2s ease;
  transition-delay: 0s;

  @media only screen and (min-width: 768px) {
    bottom: 25px;
  }

  li {
    float: none;
  }
}

@media only screen and (min-width: 520px) {
  nav ul li {
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 768px) {
  header a.logo {
    width: 191px;
    height: 72px;
    top: 3px;
    margin-left: -95px;
    background-size: 191px 72px;
  }

  header #hamburger {
    width: 70px;
    height: 70px;
  }

  header #hamburger .hamburger-holder {
    top: 25px;
    right: 20px;
  }

  #hamburger span,
  #hamburger span::before,
  #hamburger span::after {
    width: 30px;
  }

  #hamburger span::after {
    width: 25px;
  }

  #hamburger span::before {
    width: 20px;
  }

  #hamburger:hover span,
  #hamburger:hover span::before,
  #hamburger:hover span::after {
    width: 30px;
  }

  #hamburger.active span::after {
    width: 30px;
  }

  #hamburger.active span::before {
    width: 30px;
  }

  nav ul li {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 30px;
  }

  nav ul li ul {
    display: inline-block;
  }

  #menu-holder .wonen-zuid {
    position: absolute;
    width: 75px;
    height: 42px;
    bottom: 30px;
    right: 30px;
    margin-right: 0;
    background: url(../media/images/wonen-zuid.svg) no-repeat 0 0;
    background-size: 75px 42px;
    overflow: hidden;
    text-indent: -999999em;
  }
}

@media only screen and (min-width: 1024px) {
  nav ul li {
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-height: 899px) {
  nav ul li {
    font-size: 34px;
    line-height: 32px;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-height: 789px) {
  nav ul li {
    font-size: 32px;
    line-height: 30px;
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-height: 719px) {
  nav ul li {
    font-size: 30px;
    line-height: 28px;
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 768px) and (max-height: 639px) {
  nav ul li {
    font-size: 28px;
    line-height: 28px;
    margin-bottom: 25px;
  }
  nav ul li ul {
    display: none;
  }
}

@media only screen and (max-height: 539px) {
  nav {
    margin-top: -15px;
  }
  nav ul li {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  nav ul li ul {
    display: none;
  }

  .secondary-navigation {
    bottom: 10px;
  }

  #menu-holder nav {
    margin-top: -20px;
  }

  #menu-holder .wonen-zuid {
    display: none;
  }
}

@media only screen and (max-height: 229px) {
  nav {
    margin-top: -15px;
  }
  nav ul li {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 5px;
  }
}
