/* Timeline */

.filters{
  margin-top: 30px;
  text-align: center;
}

.filters button:focus{
  outline: none !important;
}

.btn.filter{
  padding: 16px 20px 15px 20px;
  cursor: pointer;
  border: 1px #dadada solid;
}

.btn.filter.is-checked{
  background-color: $darkColor;
  color: #fff;
  border: none;
}

.btn.filter::before{
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  background: none;
  left: 0;
  top: 0;
}

.filters .btn{
  margin: 0 7px 15px 7px;
}

.filters a.btn{
  display: none;
}

.timeline-component{
  padding: 0 20px;
}

.timeline-block.picture .timeline-content .timeline-content-information p{

}

@media only screen and (min-width: 768px){

  .filters a.btn{
    display: inline-block;
    background-color: $redColor;
    color: #fff;
    margin-left: 19px;
  }

  .filters a.btn::before{
    background: url(../media/images/arrow-white.svg) no-repeat;
  }

  .filters a.btn::after{
    content: '';
    width: 1px;
    height: 47px;
    background-color: #acacac;
    position: absolute;
    left: -15px;
    top: 0;
  }

  .filters a.btn:hover::before{
    transform: translateX(0);
  }

  .filters a.btn:hover{
    background-color: $darkColor;
    padding-left: 45px;
    padding-right: 20px;
  }

  .timeline-component{
    padding: 0;
  }

}

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#timeline {
  position: relative;
  padding: 2em 0;
  margin-top: 2em;
  margin-bottom: 2em;
  max-width: 520px;
  overflow: hidden;
}

#timeline::before {
  /* this is the vertical line */
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 3px;
  background: url(../media/images/timeline-dot.png);
}

#timeline .right-section{
  display: none;
}

@media only screen and (min-width: 1024px) {
  #timeline {
    margin-top: 3em;
    margin-bottom: 3em;
    max-width: none;
  }

  #timeline::before {
    left: 50%;
    margin-left: -2px;
  }

  #timeline .left-section{
    display: inline-block;
    width: 50%;
    float: left;
    min-height: 1px;
    position: relative;
    top: -30px;
  }

  #timeline .right-section{
    display: inline-block;
    position: relative;
    top: 80px;
    width: 50%;
    left: 30px;
  }

  #timeline .left-section .timeline-block:nth-child(even){
    display: none;
  }

  #timeline .right-section .timeline-block:nth-child(odd){
    display: none;
  }

}

.timeline-block {
  position: relative;
  margin: 2em 0;
}
.timeline-block:after {
  content: "";
  display: table;
  clear: both;
}
@media only screen and (min-width: 1024px) {
  .timeline-block {
    margin: 30px 0;
  }
}

.timeline-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid $redColor;
  background: #ededed;
}

.timeline-img img {
  display: block;
  margin: 0 auto;
}

.picture .timeline-img img, .event .timeline-img img{
  margin-top: 9px;
}

.news .timeline-img img {
  margin-top: 8px;
}

@media only screen and (min-width: 1024px) {
  .timeline-img {
    width: 40px;
    height: 40px;
    left: 100%;
    margin-left: -20px;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }

  #timeline .right-section .timeline-img{
    width: 40px;
    height: 40px;
    left: -30px;
  }

  .cssanimations .timeline-img.is-hidden {
    visibility: hidden;
  }
  .cssanimations .timeline-img.bounce-in {
    visibility: visible;
    -webkit-animation: bounce-1 0.6s;
    -moz-animation: bounce-1 0.6s;
    animation: bounce-1 0.6s;
  }
}

@-webkit-keyframes bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -moz-transform: scale(1.2);
  }

  100% {
    -moz-transform: scale(1);
  }
}
@keyframes bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.timeline-content {
  position: relative;
  margin-left: 60px;
  top: -6px;
  background-color: #fff;
  padding: 50px 25px 25px;
  display: block;
  color: $darkColor;
  text-decoration: none;
}

.timeline-content-information{
  background-color: #fff;
}

.timeline-content:after {
  content: "";
  display: table;
  clear: both;
}
.timeline-content h2 {
  color: $darkColor;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0;
}
.timeline-content .read-more, .timeline-content .date {
  display: inline-block;
}
.timeline-content p {
  margin-bottom: 0;
  padding-bottom: 0;
}
.timeline-content .read-more {
  float: right;
  padding: .8em 1em;
  background: #acb7c0;
  color: white;
  border-radius: 0.25em;
}
.no-touch .timeline-content .read-more:hover {
  background-color: #bac4cb;
}
.timeline-content .date {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  color: $redColor;
}

.timeline-content .date span{
  top: -2px;
  position: relative;
  margin-left: 6px;
}

.timeline-content .date span.day{
  font-size: 24px;
  background-color: $redColor;
  color: #fff;
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  padding-top: 13px;
  top: 0;
  margin-left: 0;
}

.timeline-content img{
  display: block;
  width: 100%;
  height: auto;
  margin-top: 15px;
}

@media only screen and (min-width: 768px) {
  .timeline-content h2 {
    font-size: 26px;
  }
  .timeline-content {
    margin-left: 80px;
  }
  .timeline-content::before {
    content: '';
    position: absolute;
    top: 24px;
    left: -36px;
    height: 3px;
    width: 30px;
    background: url(../media/images/timeline-dot-horizontal.png);
  }
}
@media only screen and (min-width: 1024px) {
  .timeline-content {
    margin-left: 30px;
    width: 100%;
    max-width: 380px;
    background-color: transparent;
    padding: 0;
  }

  .right-section .timeline-content{
    width: calc(100% - 60px);
  }

  .timeline-content-information{
    padding: 50px 25px 25px;
    background-color: #fff;
    position: relative;
    z-index: 1;
  }

  .timeline-content::before {
    content: '';
    position: absolute;
    top: 24px;
    left: auto;
    right: -35px;
    height: 3px;
    width: 30px;
    background: url(../media/images/timeline-dot-horizontal.png);
  }

  .timeline-block:nth-child(even) .timeline-content::before {
    left: -36px;
    right: auto;
  }

  .timeline-content .read-more {
    float: left;
  }

  .timeline-content:nth-child(even) img{
    position: relative;
    right: 30px;
    bottom: 30px;
    width: 380px;
    margin-top: 0;
  }

  .timeline-block:nth-child(even) .timeline-content img{
    position: relative;
    left: 30px;
    right: auto;
    bottom: 30px;
    width: 380px;
    margin-top: 0;
  }

  .timeline-block:nth-child(odd) .timeline-content .date{
    right: 0;
  }
  .timeline-block:nth-child(odd) .timeline-content .date span{
    float: right;
    top: 18px;
    left: -11px;
    position: relative;
  }
  .timeline-block:nth-child(odd) .timeline-content .date span.day{
    float: right;
    top: 0;
    left: 0;
  }
  .timeline-block:nth-child(odd) .timeline-content{
    text-align: right;
  }
  .timeline-block:nth-child(even) .timeline-content {
  }
  .timeline-block:nth-child(even) .timeline-content .read-more {
    float: right;
  }
  .cssanimations .timeline-content.is-hidden {
    visibility: hidden;
  }
  .cssanimations .timeline-content.bounce-in {
    visibility: visible;
    -webkit-animation: bounce-2 0.6s;
    -moz-animation: bounce-2 0.6s;
    animation: bounce-2 0.6s;
  }
}

@media only screen and (min-width: 1024px) {
  /* inverse bounce effect on even content blocks */
  .cssanimations .timeline-block:nth-child(even) .timeline-content.bounce-in {
    -webkit-animation: bounce-2-inverse 0.6s;
    -moz-animation: bounce-2-inverse 0.6s;
    animation: bounce-2-inverse 0.6s;
  }
}
@-webkit-keyframes bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes bounce-2 {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes bounce-2-inverse {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
