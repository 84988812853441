// Required Core Stylesheet
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

// Optional Theme Stylesheet
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

.slider {
    position: relative;
    padding-bottom: 40px;
    margin-bottom: 40px;
}

.content ul.glide__slides {
    padding-left: 0;
    margin-bottom: 0;
}

.glide__slide img {
    display: block;
}

.slider-controls {
    position: absolute;
    width: 290px;
    display: flex;
    background-color: #fff;
    padding: 10px 20px;
    bottom: 0;
    right: 15px;
    justify-content: space-between;
    align-items: center;

    @media only screen and (min-width: 768px){
        right: -15px;
    }

    @media only screen and (min-width: 1024px){
        right: -30px;
    }
}

.slider-controls__cta {
    font-family: $poppins;
    font-weight: 700;
    font-size: 0.750em;
    text-transform: uppercase;
}

.slider-controls__controls {
    display: flex;
    align-items: center;
}

.slider-controls__icon {
    width: 25px;
    height: 25px;
    transform: rotate(180deg);
    fill: $redColor;

    .background {
        fill: transparent;
        transition: fill 0.4s ease;
    }
    
    &:hover .background {
      fill: $redColor;
    }
    
    &:hover .arrow {
      fill: #fff;
    }

    &--next {
        transform: none;
    }
}

.glide__previous,
.glide__next {
    cursor: pointer;
}

.glide__divider {
    display: inline-block;
    width: 1px;
    height: 52px;
    background: url('/media/images/slider-divider.jpg') no-repeat;
    background-size: 100% 100%;
}

.glide__pagination {
    width: 50px;
    text-align: center;
    font-size: 1.125em;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
}

.glide__pagination-divider {
    width: 6px;
    height: 1px;
    background-color: #8a8a8a;
    display: inline-block;
    margin: 0 6px;
}

.glide__pagination-current-slide {
    color: $redColor;
}