/* FAQ */
.faq-cat-title {
  margin-top: 80px;
}


.faq-question {
  max-width: 520px;
  margin: 0 auto;
  border-top: 1px #acacac solid;
  position: relative;
}

.faq-question h2, .faq-question p {
  padding: 0 30px;
}

.faq-question p {
  display: none;
}

.faq-question.expanded p {
  display: block;
}

.faq-question p a{
  font-weight: 700;
  text-decoration: underline;
  color: $redColor;
  transition: color 0.2s ease;
}

.faq-question p a:hover{
  color: $darkColor;
}

.faq-question h2 {
  font-size: 18px;
  line-height: 26px;
  text-transform: none;
  margin-top: 20px;
  cursor: pointer;
}

.faq-question h2::before {
  content: '';
  width: 16px;
  height: 10px;
  position: absolute;
  background: url(../media/images/chevron.svg) no-repeat;
  top: 27px;
  left: 0;
}

.faq-question.expanded h2::before {
  transform: rotate(180deg);
}

.faq-question:last-child{
  border-bottom: 1px #acacac solid;
}



@media only screen and (min-width: 768px){

  .faq-question{
    max-width: none;
  }

  .faq-question h2, .faq-question p {
    padding: 0 50px;
  }

  .faq-question h2::before{
    left: 10px;
  }

}
