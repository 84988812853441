/* Agenda */

section#agenda-overview a{
	text-decoration: none;
}

.agenda-event{
	margin-bottom: 20px;
	text-decoration: none;
}

.agenda-event .date {
    width: 80px;
    height: 80px;
    padding: 12px 0px;
    background-color: $redColor;
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    box-sizing: border-box;
    float: left;
    display: inline-block;
		transition: all 0.2s ease;
}

.agenda-event:hover .date {
    background-color: $darkColor;
}

.agenda-event .date span {
	font-size: 12px;
	display: block;
	font-weight: 600;
	line-height: 14px;
	text-transform: uppercase;
	margin-top: 0;
}

.agenda-event .date span.day {
	font-size: 30px;
	display: block;
	margin-bottom: 0;
	font-weight: 700;
	line-height: 30px;
}

.agenda-event .title {
    height: 80px;
    width: calc(100% - 80px);
    box-sizing: border-box;
    padding: 0 30px;
    color: $darkColor;
    font-size: 12px;
    line-height: 14px;
    background-color: #fff;
    float: left;
    display: table;
		text-transform: uppercase;
		font-weight: 700;
}

.agenda-event .title span{
	position: relative;
	display: table-cell;
  top: 2px;
  vertical-align: middle;
}

 .agenda-event .time {
    background-color: #fff;
    clear: both;
    margin-top: 1px;
    margin-left: 80px;
    box-sizing: border-box;
    padding: 10px;
}

 .agenda-event .time span.time-holder {
    color: #999999;
    font-size: 12px;
    line-height: 16px;
    padding-left: 25px;
		padding-top: 4px;
    display: table-cell;
		vertical-align: middle;
    background: url(../media/images/clock.png) no-repeat left center;
}

.agenda-event .time span.time-holder span{
	margin-right: 15px;
	position: relative;
}

.agenda-event .time span.time-holder span:first-child::after{
	content: '-';
	font-size: 12px;
	line-height: 16px;
	position: absolute;
	color: #999999;
	left: 33px;
}

 .agenda-event .time span br {
    display: none;
}

 .agenda-event .location {
    background-color: #fff;
    box-sizing: border-box;
    padding: 10px;
    margin-left: 80px;
}

 .agenda-event .location span {
	 	padding-top: 4px;
    color: #acacac;
    font-size: 12px;
    line-height: 16px;
    padding-left: 25px;
		text-transform: uppercase;
    display: table-cell;
		vertical-align: middle;
    background: url(../media/images/pointer.png) no-repeat left center;
}

@media only screen and (min-width: 520px){
	.agenda-event .time span.time-holder span{
		margin-right: 0;
		position: relative;
	}

	.agenda-event .time span.time-holder span:first-child::after{
		content: '';
		display: none;
	}

	.agenda-event .title {
		 font-size: 16px;
		 line-height: 18px;;
	}

	.agenda-event .time span.time-holder {
		 padding-left: 35px;
	 }

	 .agenda-event .time {
	    background-color: #fff;
	    float: left;
	    display: table;
	    width: calc(40% - 40px);
	    height: 80px;
	    margin-top: 1px;
	    margin-left: 80px;
	    box-sizing: border-box;
	    padding: 0 30px;
	    clear: none;
	}

	.agenda-event .time span br,  .agenda-event .location span br {
    display: block;
	}

	.agenda-event .location {
    background-color: #fff;
    float: left;
    display: table;
    width: calc(60% - 40px);
    height: 80px;
    margin-top: 1px;
    margin-left: 0;
    box-sizing: border-box;
    padding: 0 10px;
	}

}

@media only screen and (min-width: 768px){

	.agenda-event .date {
		width: 110px;
		height: 110px;
	  padding: 15px 0px;
	}

	.agenda-event .date span.day {
		font-size: 36px;
		line-height: 36px;
	}

	.agenda-event .date span {
    margin-top: 4px;
		font-size: 14px;
	}

	.agenda-event .title {
	   width: 307px;
		 height: 110px;
	}

 .agenda-event .time {
    width: 91px;
    margin: 0;
    position: relative;
		padding: 0 10px;
		height: 110px;
	}

	.agenda-event .time:after {
    content: '';
    position: absolute;
    left: 0;
    top: 30px;
    height: 50px;
    width: 1px;
    background-color: #ededed;
	}

	.agenda-event .location {
    width: 220px;
		height: 110px;
    margin: 0;
    position: relative;
	}

	.agenda-event .location:after {
    content: '';
    position: absolute;
    left: 0;
    top: 30px;
    height: 50px;
    width: 1px;
    background-color: #ededed;
	}

}

@media only screen and (min-width: 1024px){
	.agenda-event .title {
	    width: 505px;
	}

	.agenda-event .title {
	    font-size: 28px;
	    line-height: 30px;
	}

	.agenda-event .time {
		width: 91px;
    padding: 0 10px;
	}

	 .agenda-event .location {
    width: 234px;
    padding: 0 10px;
	}
}
