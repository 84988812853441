/* Action blocks */

.action-block{
  position: relative;
  width: 100%;
  height: auto;
  background-color: $darkColor;
  padding: 30px 20px;
  box-sizing: border-box;
  color: #fff;
  margin-bottom: 20px;
}

.action-block.red{
  background-color: $redColor;
}

.action-block h3{
  padding: 0 10px;
  text-transform: uppercase;
  font-size: 22px;
  line-height: 24px;
  margin: 0 0 20px;
}

.action-block img{
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.action-block p{
  margin: 0;
  padding: 0 10px;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 10px;
}

.action-block p a{
  font-weight: 700;
  color: #fff;
  text-decoration: underline;
  transition: color 0.2s ease;
}

.action-block p a:hover{
  color: $redColor;
}

.action-block.red p a:hover{
  color: $darkColor;
}

.action-block .readmore {
    display: block;
    width: 28px;
    height: 28px;
    float: right;
}

/* Read more normal */
.action-block .readmore svg .background {
	fill: $darkColor;
	transition: fill 0.2s ease;
}

/* Read more red */
.action-block.red .readmore svg .background {
	fill: $redColor;
}

.action-block.red svg .border {
	fill: $darkColor;
}

.action-block.red .readmore svg .arrow {
    fill: $darkColor;
}

/* Read more hover */
.action-block.red .readmore:hover svg .background {
    fill: $darkColor;
}

.action-block .readmore:hover svg .background {
    fill: $redColor;
}

.action-block .readmore:hover svg .arrow {
    fill: #fff;
}

.video-holder {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.video-holder iframe, .video-holder object, .video-holder embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

iframe.vimeo #player .controls .play:hover{
  background-color: $redColor !important;
}


@media only screen and (min-width: 1024px){

  .action-block .readmore {
      margin-top: 5px;
  }

}
