/* Area */

main#area-single{
  z-index: 5;
  position: relative;
}

main#area-single .page-top-content {
    margin-top: 100px;
}

main#area-single a.readmore{
	display: inline-block;
	text-decoration: none;
  position: absolute;
  margin-top: -60px;
  margin-left: -17px;
}

main#area-single a.readmore span{
	font-size: 14px;
	color: $redColor;
	text-decoration: none;
	position: absolute;
	top: -25px;
  left: -53px;
	width: 150px;
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.5s ease;
}

main#area-single a.readmore:hover span{
	visibility: visible;
	opacity: 1;
}

main#area-single a.readmore svg{
	width: 35px;
	height: 35px;
	transform: rotate(180deg);
}

main#area-single .readmore svg .background {
	fill: transparent;
	transition: fill 0.4s ease;
}

main#area-single .readmore:hover svg .background {
  fill: $redColor;
}

main#area-single .readmore:hover svg .arrow {
  fill: #fff;
}

main#area-single h2.main-title {
  margin-top: 50px;
}

section#area-files{
  width: 100%;
  overflow: hidden;
}

section#area-files .container{
  padding: 80px 20px;
  box-sizing: border-box;
  margin: 60px auto 40px;
  background-color: #fff;
  position: relative;
}

section#area-files .container::before{
  content: '';
  position: absolute;
  width: 200px;
  height: 100%;
  top: 0;
  left: -200px;
  background: -moz-linear-gradient(left, rgba(237,237,237,1) 0%, rgba(255,252,252,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(237,237,237,1)), color-stop(100%, rgba(255,252,252,1)));
  background: -webkit-linear-gradient(left, rgba(237,237,237,1) 0%, rgba(255,252,252,1) 100%);
  background: -o-linear-gradient(left, rgba(237,237,237,1) 0%, rgba(255,252,252,1) 100%);
  background: -ms-linear-gradient(left, rgba(237,237,237,1) 0%, rgba(255,252,252,1) 100%);
  background: linear-gradient(to right, rgba(237,237,237,1) 0%, rgba(255,252,252,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#fffcfc', GradientType=1 );
}

section#area-files .container::after{
  content: '';
  position: absolute;
  width: 200px;
  height: 100%;
  top: 0;
  right: -200px;
  background: -moz-linear-gradient(left, rgba(255,252,252,1) 0%, rgba(237,237,237,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,252,252,1)), color-stop(100%, rgba(237,237,237,1)));
  background: -webkit-linear-gradient(left, rgba(255,252,252,1) 0%, rgba(237,237,237,1) 100%);
  background: -o-linear-gradient(left, rgba(255,252,252,1) 0%, rgba(237,237,237,1) 100%);
  background: -ms-linear-gradient(left, rgba(255,252,252,1) 0%, rgba(237,237,237,1) 100%);
  background: linear-gradient(to right, rgba(255,252,252,1) 0%, rgba(237,237,237,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fffcfc', endColorstr='#ededed', GradientType=1 );
}

section#area-files .file{
  display: inline-block;
  width: auto;
  padding: 0;
  color: $darkColor;
  text-decoration: none;
  position: relative;
  margin: 30px 0 0;
  text-align: center;
  padding-bottom: 35px;
}

section#area-files .file::before{
  left: 50%;
  bottom: 0px;
  content: '';
  margin-left: -13px;
  position: absolute;
  width: 26px;
  height: 23px;
  background: url(../media/images/download.svg) no-repeat;
}

section#area-files .file h3{
  font-size: 16px;
  text-align: center;
}

section#area-files .file p{
  line-height: 22px;
  padding-bottom: 0;
  text-align: center;
}

@media only screen and (min-width: 768px){
  
  main#area-single .page-top-content {
      margin-top: 200px;
  }

  section#area-files .file{
    display: inline-block;
    width: 310px;
    padding:  0 40px 0 100px;
    box-sizing: border-box;
    color: $darkColor;
    margin: 20px 0;
    text-align: left;
    padding-bottom: 0;
  }

  section#area-files .file h3{
    text-align: left;
  }

  section#area-files .file p{
    text-align: left;
  }

  section#area-files .file::before{
    left: 40px;
    top: 0;
    bottom: auto;
    content: '';
    margin-left: 0;
  }

  main#area-single a.readmore{
    margin-left: 0;
  }

  main#area-single a.readmore span{
    top: 10px;
    left: 45px;
  }

  section#area-files .container{
    padding: 60px 0;
    text-align: center;
  }

  section#area-files .file{
    width: 340px;
  }

  section#area-files .content{
    display: inline-block;
  }

  section#area-files .content .flex-box{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  section#area-files .content>[class*=file] {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

}

@media only screen and (min-width: 1024px){

  section#area-files .content{
    padding: 0;
  }

  section#area-files .file{
    width: 310px;
  }

}
