/* Vue */
[v-cloak]{
  display: none;
}

/* Grid */
main#content{
  z-index: 5;
  position: relative;
}

.container{
	width: 100%;
	margin: 0 auto;
	padding: 0 20px;
  box-sizing: border-box;
}

.grid-2{

}

.grid-1{
  width: 280px;
  margin: 0 auto;
  margin-top: 80px;
}

.grid-half{
  width: 100%;
}

.content{
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
  text-align: center;
}

.content p, .content h1, .content h2, .content h3, .content h4, .content h5, .content h6, .content p, .content img, .content ul, .content figcaption{
  text-align: left;
}

.content h2{
  text-transform: uppercase;
  font-size: 24px;
  margin-top: 0;
}

.content h3{
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 8px;
}

.content img{
  width: 100%;
}

.content p{
  margin: 0;
  padding-bottom: 30px;
}

.content p a,
.content ul li a {
  font-weight: 700;
	text-decoration: underline;
	color: $redColor;
  transition: color 0.2s ease;
}

.content ul li a {
  font-weight: normal;
}

.content p a:hover,
.content ul li a:hover {
	color: $darkColor;
}

.content p.no-padding-bottom{
  padding-bottom: 5px;
}

.content ul {
  font-size: 14px;
  line-height: 24px;
  margin-top: 0;
  margin-bottom: 30px;
  padding-left: 20px;
}

.content figcaption{
  font-size: 12px;
  line-height: 16px;
  color: #919191;
  position: relative;
  top: -25px;
}

.content .social-holder{
  width: auto;
}

.content .social{
  border-bottom: none;
}

.content blockquote {
  font-size: 1.500em;
  font-family: $brushed, Arial, sans-serif;
  transform: rotate(-1deg);
  font-weight: normal;
  text-align: center;
  margin: 10px 0 40px;

  @media only screen and (min-width: 768px) {
    font-size: 3.125em;
    margin: 50px 0 80px;
  }
}

.content .video-container {
  position: relative;
	padding-bottom: 56.25%;
  height: 0;
  margin-bottom: 30px;
}

.content .video-container iframe {
  position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.group:after {
    content: "";
    display: table;
    clear: both;
}

.clear {
    clear: both;
}

/* Elements */
h1, h2, h3, h4, h5, h6{
  font-family: $poppins;
  font-weight: 700;
}

p{
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
  font-family: $poppins;
  padding-bottom: 20px;
}

p strong{
  font-weight: 600;
}

h2{
  font-size: 24px;
	 line-height: 1.1em;
}

h2.main-title {
    font-family: $brushed, Arial, sans-serif;
    text-align: center;
    margin: 0 0 17px 0;
    padding: 0;
    transform: rotate(-1deg);
    font-weight: normal;
}

h2.main-title span {
    font-size: 44px;
    line-height: 1em;
    display: inline-block;
    padding: 5px 10px;
    background-color: #fff;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
}

textarea:focus, input:focus{
    outline: none;
}

/* Buttons */

.btn{
  display: inline-block;
  font-size: 16px;
  line-height: 16px;
  background-color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
  padding: 16px 20px 15px 45px;
  border-radius: 25px;
  color: $darkColor;
  position: relative;
  transition: all 0.2s ease;
}

.btn:hover{
  color: $redColor;
  padding-left: 50px;
  padding-right: 25px;
}

.action-block .btn{
  margin-left: 5px;
}

.btn::before{
  content: '';
  width: 15px;
  height: 12px;
  position: absolute;
  background: url(../media/images/arrow.svg) no-repeat;
  left: 20px;
  top: 17px;
  transform: translateX(0);
  transition: all 0.2s ease;
}

.btn:hover::before{
  transform: translateX(5px);
}

.btn.facebook-event{
  margin-bottom: 30px;
}

.btn.facebook-event::before{
  content: '';
  width: 8px;
  height: 17px;
  position: absolute;
  background: url(../media/images/facebook-red.svg) no-repeat;
  left: 20px;
  top: 15px;
}

.readmore svg{
	position: relative;
	transform: translateZ(0);
}

.readmore svg .arrow {
	fill: $redColor;
}

.readmore svg .border{
	fill: $redColor;
}

.readmore svg .background {
	fill: #fff;
	transition: fill 0.4s ease;
}

.readmore svg .arrow {
  fill: $redColor;
  transition: fill 0.4s ease;
}

@media only screen and (min-width: 768px){
	.container{
		width: 728px;
    padding: 0;
	}

  .grid{
    float: left;
  }

  .grid-2{
    width: 478px;
  }

  .grid-1{
    width: 228px;
    margin-left: 22px;
  }

  .grid-half{
    width: calc(50% - 10px);
    margin-right: 20px;
  }

  .content{
    padding: 0 20px;
    box-sizing: border-box;
    max-width: none;
    text-align: left;
  }

  .content h2{
    font-size: 38px;
  }

  .grid-1{
    margin-top: 0;
  }

}

@media only screen and (min-width: 1024px){

	.container{
		width: 940px;
	}

  .grid-2{
    width: 620px;
  }

  .grid-1{
    width: 300px;
    margin-left: 20px;
  }

  .content{
  	padding: 0 50px;
  }

}

@media only screen and (min-width: 1280px){
  #page-title-holder {
    width: 1120px;
  }
}

@media only screen and (min-width: 1400px){
  #page-title-holder {
    width: 1280px;
  }
}