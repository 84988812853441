.house-residents {
    margin-bottom: 10px;

    @media only screen and (min-width: 768px) {
        margin-bottom: 20px;
    }
}

.house-resident {
    position: relative;
    margin-top: 20px;
    margin-bottom: 40px;
}

.house-resident__visual {
    position: relative;
    margin-bottom: 55px;
}

.house-resident__image {
    
}

.house-resident__info {
    position: absolute;
    background-color: #fff;
    width: 280px;
    min-height: 85px;
    bottom: -30px;
    right: -15px;
    padding: 20px;
    text-align: left;

    @media only screen and (min-width: 768px) {
        width: 230px;
        min-height: 85px;
        padding: 20px;
        right: -30px;
    }

    @media only screen and (min-width: 1024px) {
        width: 290px;
        min-height: 100px;
    }
}

.content .house-resident__name {
    text-transform: uppercase;
    color: #000000;
    font-size: 1.5em;
    line-height: 1.1em;
    font-weight: 700;
    letter-spacing: -0.05em;
    margin: 0;
    padding: 0;
    
    @media only screen and (min-width: 768px) {
        font-size: 1em;
        line-height: 1.2em;
    }

    @media only screen and (min-width: 1024px) {
        font-size: 1.5em;
        line-height: 1.1em;
    }
}

.house-resident__description {
    display: block;
    color: $redColor;
    font-size: 0.75em;
    line-height: 1.15em;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 6px;
    
    @media only screen and (min-width: 768px) {
        font-size: 0.8em;
    }
}

section.split-section .grid-half.become-residents {
    padding: 50px 30px;
    background-color: $redColor;
    height: auto;
    margin-top: 60px;

    @media only screen and (min-width: 768px) {
        position: relative;
        margin-top: 30px;
        height: 265px;
        padding: 30px;
    }

    @media only screen and (min-width: 1024px) {
        height: 345px;
    }

    h2 {
        color: #fff;
        margin-bottom: 20px;
        
        @media only screen and (min-width: 768px) {
            width: 220px;
            margin-bottom: 0;
        }
    }

    p {
        color: #fff;
    }

    .btn {
        @media only screen and (min-width: 768px) {
            position: absolute;
            bottom: 30px;
        }
    }
}

#resident {
    a.readmore{
        display: inline-block;
        text-decoration: none;
        position: absolute;
        margin-top: -60px;
        margin-left: -17px;
        
        @media only screen and (min-width: 768px){
            margin-left: 0;
        }
    }
    
    a.readmore span{
        font-size: 14px;
        color: $redColor;
        text-decoration: none;
        position: absolute;
        top: -25px;
        left: -53px;
        width: 165px;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.5s ease;

        @media only screen and (min-width: 768px) {
            top: 10px;
            left: 45px;
        }
    }
    
    a.readmore:hover span{
        visibility: visible;
        opacity: 1;
    }
    
    a.readmore svg{
        width: 35px;
        height: 35px;
        transform: rotate(180deg);
    }
    
    a.readmore svg .background {
        fill: transparent;
        transition: fill 0.4s ease;
    }
    
    a.readmore:hover svg .background {
      fill: $redColor;
    }
    
    a.readmore:hover svg .arrow {
      fill: #fff;
    }

    section.page-top-content {
        margin-top: 80px;
        
        @media only screen and (min-width: 768px) {
            margin-top: 200px;
        }
    }

    .content img {
        margin-bottom: 30px;
    }
    
    .content-info{
        color: $redColor;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 20px;
        margin-top: 15px;
        overflow: hidden;
        margin-bottom: 20px;
        text-align: left;

        @media only screen and (min-width: 768px) {
            margin-bottom: 10px;
        }

        span {
            display: block;

            @media only screen and (min-width: 768px) {
                display: inline-block;
            }
        }
    }
      
    .content-info span.date{
        @media only screen and (min-width: 768px) {
            padding-right: 10px;
            border-right: 1px $darkColor solid;
        }
    }
      
    .content-info span.content-type{
        @media only screen and (min-width: 768px) {
            padding-left: 7px;
        }
    }
}