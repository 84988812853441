/* Timeline content single */

section#timeline-content-single ul{
	font-size: 14px;
	line-height: 24px;
	margin-top: 0;
	margin-bottom: 30px;
}

section#timeline-content-single a.readmore{
	display: inline-block;
	text-decoration: none;
	position: relative;
}

section#timeline-content-single a.readmore span{
	font-size: 14px;
	color: $redColor;
	text-decoration: none;
	position: absolute;
	top: -25px;
  left: -53px;
	width: 150px;
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.5s ease;
}

section#timeline-content-single a.readmore:hover span{
	visibility: visible;
	opacity: 1;
}

section#timeline-content-single a.readmore svg{
	width: 35px;
	height: 35px;
	transform: rotate(180deg);
}

section#timeline-content-single .readmore svg .background {
	fill: transparent;
	transition: fill 0.4s ease;
}

section#timeline-content-single .readmore:hover svg .background {
  fill: $redColor;
}

section#timeline-content-single .readmore:hover svg .arrow {
  fill: #fff;
}

section#timeline-content-single .content-info{
  color: $redColor;
	font-size: 14px;
	font-weight: bold;
	text-transform: uppercase;
	line-height: 14px;
	margin-top: 15px;
	overflow: hidden;
}

section#timeline-content-single .content-info span.date{
	padding-right: 10px;
	border-right: 1px $darkColor solid;
}

section#timeline-content-single .content-info span.content-type{
	padding-left: 7px;
}

section#timeline-content-single h1{
	text-transform: uppercase;
	font-size: 24px;
	margin-top: 40px;
}

section#timeline-content-single .content{
	width: 100%;
}

section#timeline-content-single .content h2 {
    font-size: 18px;
    margin-top: 0;
    margin-bottom: 8px;
		text-transform: none;
}

section#timeline-content-single .content h3 {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 8px;
}

section#timeline-content-single img.news-header{
	width: 100%;
	height: auto;
	margin-bottom: 30px;
}

section#timeline-content-single .content img{
	display: inline-block;
	width: 100%;
	height: auto;
}

@media only screen and (min-width: 768px){

	section#timeline-content-single h1{
		margin-top: 10px;
		font-size: 38px;
	}

	section#timeline-content-single .content-info{
		margin-top: 30px;
	}

	section#timeline-content-single a.readmore span{
		top: 10px;
		left: 45px;
	}

}
