/* Page */

h1.page-title{
  font-family: $brushed, Arial, sans-serif;
  text-align: center;
  margin: 60px 0 17px 0;
  padding: 0;
  transform: rotate(-1deg);
  font-weight: normal;
}

h1.page-title span{
  font-size: 45px;
  line-height: 1em;
  display: inline-block;
  padding: 10px 15px;
  background-color: #fff;
}

h1.algemene-voorwaarden span{
  font-size: 40px;
}

section.page-top-content{
  margin-top: 60px;
}

main#content.no-header h1.page-title{
  margin: 60px 0 17px 0;
}

main#content.no-header section.page-top-content{
  margin-top: 50px;
}

section.highlight-section{
  margin-top: 80px;
  background-color: $redColor;
  color: #fff;
  padding: 120px 0;
  box-sizing: border-box;
  background-image: url(../media/images/dots.svg);
  background-size: 263px 420px;
  background-position: top right;
  background-repeat: no-repeat;
  position: relative;
}

section.highlight-section::before{
  content: '';
  width: 263px;
  height: 420px;
  position: absolute;
  background: url(../media/images/dots.svg) no-repeat;
  transform: rotate(180deg);
  left: 0;
  bottom: 0;
  z-index: 0;
}

section.highlight-section h2.main-title{
    color: $darkColor;
    text-align: left;
   margin-bottom: 40px;;
}

section.highlight-section .content{
  position: relative;
  z-index: 5;
}

section.highlight-section .content p a{
  color: #fff;
  font-weight: 700;
}

section.highlight-section .content p a:hover{
	color: $darkColor;
}

section.split-section{
    margin-top: 70px;
}

section.split-section .grid-half{
  max-width: 520px;
  margin: 0 auto;
  margin-top: 30px;
}

section.split-section img{
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 25px;
}

section.split-section h2{
  font-size: 22px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
}

section.split-section p{
  padding-bottom: 0;
}

section.split-section .readmore{
  position: relative;
  display: inline-block;
  width: 28px;
  height: 28px;
  float: right;
}

section.split-section .readmore svg .background {
	fill: transparent;
	transition: fill 0.4s ease;
}

section.split-section .readmore:hover svg .background {
    fill: $redColor;
}

section.split-section .readmore:hover svg .arrow {
    fill: #fff;
}

section#areas{
  margin-top: 100px;
}

.area-item {
  display: block;
  width: 280px;
  height: 300px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin: 0 auto 20px;
}

.area-item .label {
    background-color: #fff;
    position: absolute;
    right: 0;
    width: 290px;
    bottom: 10px;
    box-sizing: border-box;
    padding: 20px 40px 20px 20px;
    -webkit-transition: bottom 0.4s ease;
    transition: bottom 0.4s ease;
    z-index: 5;
}

.area-item:hover .label {
    bottom: 30px;
}

.area-item::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 3;
    transition: background-color 0.4s ease;
}

.area-item:hover::after {
    background-color: rgba(0, 0, 0, 0.1);
}

.area-item .label h3 {
    text-transform: uppercase;
    color: #000000;
    font-size: 1.5em;
    line-height: 1.1em;
    font-weight: 700;
    letter-spacing: -0.05em;
    margin: 0;
    padding: 0;
}

.area-item .label p {
    color: #000000;
    font-size: 14px;
    line-height: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.area-item .readmore {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 28px;
    height: 28px;
}

.area-item:hover .readmore svg .background {
    fill: $redColor;
}

.area-item:hover .readmore svg .arrow {
    fill: #fff;
}

@media only screen and (min-width: 768px){
  section.page-top-content{
    margin-top: 200px;
  }

  h1.page-title{
    margin: 120px 0 17px 0;
  }

  h1.page-title span{
    font-size: 90px;
  }

  h1.algemene-voorwaarden span{
    font-size: 60px;
  }

  section.split-section .grid-half{
    margin-right:  20px;
  }

  section.split-section .grid-half:nth-child(2n){
    margin-right:  0;
  }

  .area-item {
    float: left;
    width: 354px;
    height: 354px;
    margin: 0 20px 20px 0;
	}

  .area-item:nth-child(odd) {
    margin: 0 0 20px 0;
  }

	.area-item.first {
    margin-left: 0;
    width: 478px;
    height: 478px;
	}

	.area-item .label {
    padding: 10px 20px 10px 10px;
    width: 214px;
	}

	.area-item .label h3 {
    font-size: 1em;
    line-height: 1.2em;
	}

  .area-item .label p {
      font-size: 12px;
      line-height: 18px;
  }

	.area-item .readmore {
    width: 20px;
    height: 20px;
	}

	.area-item .readmore svg.readmore_arrow {
    width: 10px;
    height: auto;
    top: 6px;
    left: 5px;
	}

}

@media only screen and (min-width: 1024px){

  .area-item {
    width: 460px;
    height: 460px;
    float: left;
	}

	.area-item .label {
    padding: 20px 40px 20px 20px;
    width: 290px;
	}

	.area-item .label h3 {
	  font-size: 1.5em;
	  line-height: 1.1em;
	}

	.area-item .readmore {
    width: 28px;
    height: 28px;
	}

	.area-item .readmore svg.readmore_arrow {
    width: 12px;
    height: 9px;
    top: 9px;
    left: 8px;
	}

  .area-item .label p {
    font-size: 14px;
    line-height: 20px;
  }

}
