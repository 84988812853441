/* Houses */
#houses,
#house {
    section.page-top-content {
        margin-top: 105px;
        
        @media only screen and (min-width: 768px){
            margin-top: 160px;
        }
    }
    
    .area-item {
        text-decoration: none;
    }

    .area-item .label {
        padding: 20px 45px 20px 20px;
        
        p {
            color: $redColor;
            font-size: 0.75em;
            line-height: 1.15em;
            font-weight: 600;
            text-transform: uppercase;
            padding-bottom: 5px;
            
            @media only screen and (min-width: 768px){
                font-size: 0.8em;
            }
        }

        @media only screen and (min-width: 768px){
            width: 290px;
            padding: 10px 20px 10px 10px;
        }

        @media only screen and (min-width: 1024px){
            width: 380px;
            padding: 20px 45px 20px 20px; 
        }
    }

    .area-item .rented-label {
        width: 130px;
        padding: 12px 10px 12px 10px;
        text-decoration: none;
        background-color: $redColor;
        margin-top: 10px;
        
        @media only screen and (min-width: 768px){
            width: 150px;
            padding: 16px 10px 15px 10px;
        }

        p {
            color: #fff;
            font-size: 0.75em;
            line-height: 1.15em;
            font-weight: 600;
            text-transform: uppercase;
            padding: 0;
            margin: 0;
            
            @media only screen and (min-width: 768px){
                font-size: 1em;
            }
        }
    }
}

#houses {
    #main-content {
        margin-bottom: 40px;

        @media only screen and (min-width: 768px){
            margin-bottom: 90px;
        }
    }


}

#house {
    #action-blocks {
        margin-bottom: 100px;
    }
    
    #main-content.no-sidebar {
        margin-bottom: 40px;

        @media only screen and (min-width: 768px){
            margin-bottom: 90px;
        }
    }

    a.readmore{
        display: inline-block;
        text-decoration: none;
        position: absolute;
        margin-top: -60px;
        margin-left: -17px;
        
        @media only screen and (min-width: 768px){
            margin-left: 0;
        }
    }
    
    a.readmore span{
        font-size: 14px;
        color: $redColor;
        text-decoration: none;
        position: absolute;
        top: -25px;
        left: -53px;
        width: 150px;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.5s ease;

        @media only screen and (min-width: 768px){
            top: 10px;
            left: 45px;
        }
    }
    
    a.readmore:hover span{
        visibility: visible;
        opacity: 1;
    }
    
    a.readmore svg{
        width: 35px;
        height: 35px;
        transform: rotate(180deg);
    }
    
    a.readmore svg .background {
        fill: transparent;
        transition: fill 0.4s ease;
    }
    
    a.readmore:hover svg .background {
      fill: $redColor;
    }
    
    a.readmore:hover svg .arrow {
      fill: #fff;
    }
}