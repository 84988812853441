/* Footer */
/* Footer  */

footer {
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  margin-top: 100px;
}

footer .newsletter-section{
  padding: 40px 0;
  box-sizing: border-box;
  background-color: $redColor;
  color: #fff;
}

footer .footer-section{
  padding: 25px 20px 0px;
  box-sizing: border-box;
}

footer h5 {
  padding: 0;
  margin: 0 0 6px;
  font-size: 24px;
  line-height: 1em;
  font-family: $poppins;
  font-weight: 700;
  letter-spacing: -0.05em;
}

footer p.newsletter-txt {
    font-size: 14px;
    line-height: 1.4em;
    font-family: $poppins;
    font-weight: 400;
    letter-spacing: -0.05em;
    width: 100%;
    max-width: 400px;
    margin: 5px auto 0;
}

.newsletter-holder {
    width: 100%;
    margin: 0 auto;
}

footer p.newsletter-success {
    color: #fff;
    font-size: 0.75em;
    line-height: 1.4em;
    font-family: $poppins;
    font-weight: 400;
    letter-spacing: -0.05em;
    width: 100%;
    max-width: 340px;
    margin: 0 auto 20px;
}

.newsletter-holder #fieldEmail {
    box-sizing: border-box;
    width: 100%;
    max-width: 300px;
    background-color: #fff;
    border: 1px solid #cccccc;
    border-radius: 10px;
    height: 50px;
    padding: 0 18px;
    color: #999999;
    font-size: 12px;
    line-height: 12px;
    font-family: $poppins;
    font-weight: 400;
    letter-spacing: -0.05em;
    margin: 0 auto 15px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.newsletter-holder button {
    box-sizing: border-box;
    width: 110px;
    height: 50px;
    border-radius: 10px;
    background-color: $darkColor;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1;
    border: none;
    text-align: center;
    margin: 0 auto;
    font-family: $poppins;
    font-weight: 600;
    letter-spacing: -0.05em;
    -webkit-transition: background-color 0.3s ease;
    transition: background-color 0.3s ease;
    padding-top: 3px;
}

.newsletter-holder button:hover {
    background-color: #252525;
}

.social {
    width: 100%;
    margin: 0 auto;
		border-bottom: 1px #ccc solid;
}

.social-holder {
    width: 141px;
		margin: 0 auto;
		padding: 0 0 20px;
}

.social a {
    display: inline-block;
    float: left;
    width: 40px;
    height: 40px;
    background-color: $redColor;
    margin-left: 10px;
    border-radius: 50%;
    text-indent: -999999em;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
}

.social a:first-child {
    margin-left: 0;
}

.social a.facebook {
    background-image: url(../media/images/facebook.svg);
    background-size: 8px 16px;
}

.social a.twitter {
    background-image: url(../media/images/twitter.svg);
    background-size: 16px 15px;
}

.social a.instagram {
    background-image: url(../media/images/instagram.svg);
    background-size: 15px 15px;
}

.social a.pinterest {
    background-image: url(../media/images/pinterest.svg);
    background-size: 13px 16px;
}

.social a.youtube {
    background-image: url(../media/images/youtube.svg);
    background-size: 15px 10px;
}

.social a:hover {
    -webkit-animation: btn-hover-effect 1s forwards;
    animation: btn-hover-effect 1s forwards;
    background-color: #151515;
}

footer .info{
  margin: 25px 0 25px;
  color: #acacac;
  font-size: 12px;
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 400;
  letter-spacing: -0.05em;
  line-height: 1em;
  width: 100%;
  display: table;
}

footer .info a{
  color: #acacac;
  text-decoration: none;
}

footer .info .copyright{
  position: relative;
  display: block;
  padding-bottom: 20px;
}

footer .info .provincie{
  height: 55px;
  display: block;
  margin: 0 auto;
  width: 180px;
  background: url(../media/images/logo_provincie.svg) no-repeat center 0;
  background-size: 169px auto;
  overflow: hidden;
  text-indent: -999999em;
  position: relative;
  padding-bottom: 20px;
}

footer .info .wonen-zuid{
  display: block;
  width: 155px;
  height: 42px;
  position: relative;
  margin: 0 auto;
}

footer .info .wonen-zuid span.powered-by{
  top: 50%;
  position: absolute;
  margin-left: -75px;
  transform: translateY(-50%);
}

footer .info .wonen-zuid span.wonen-zuid-logo{
  position: absolute;
  display: inline-block;
  width: 75px;
  height: 42px;
  background: url(../media/images/wonen-zuid-black.svg) no-repeat 0 0;
  background-size: 75px 42px;
  overflow: hidden;
  text-indent: -999999em;
}

@-webkit-keyframes btn-hover-effect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}

@keyframes btn-hover-effect {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
}


@media only screen and (min-width: 480px){
	.newsletter-holder {
	    width: 420px;
	}
	.newsletter-holder #fieldEmail {
    margin: 0 10px 0 0;
    float: left;
	}
	.newsletter-holder button {
    margin: 0;
	}
}

@media only screen and (min-width: 768px){

  footer .info .provincie{
    display: table-cell;
    vertical-align: middle;
    top: 5px;
    margin: auto;
    padding-bottom: 0;
  }

  footer .info .copyright{
    display: inline;
    top: 18px;
    padding-bottom: 0;
  }

  footer .info .wonen-zuid{
    width: 155px;
    height: 42px;
    float: right;
    left: -5px;
    position: relative;
    display: table-cell;
    vertical-align: middle;
  }

}
