.social-share {
    margin-bottom: 60px;
    font-family: $poppins;
    font-weight: 700;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media only screen and (min-width: 768px) {
        flex-direction: row;
        margin-bottom: 100px;
    }
}

.social-share--resident {
    margin-bottom: 0;
    margin-top: 10px;

    @media only screen and (min-width: 768px) {
        margin-top: 30px;
    }
}

.social-share__links {
    margin-left: 0;
    
    @media only screen and (min-width: 768px) {
        margin-left: 25px;
    }
}

.social-share__link {
    display: inline-block;
    float: left;
    width: 40px;
    height: 40px;
    background-color: $redColor;
    margin-left: 10px;
    border-radius: 50%;
    text-indent: -999999em;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    margin-top: 15px;
    
    @media only screen and (min-width: 768px) {
        margin-top: 0;
    }

    &:first-of-type {
        margin-left: 0;
    }

    &:hover {
        animation: btn-hover-effect 1s forwards;
        background-color: #151515;
    }
}

.social-share__link--facebook {
    background-image: url('../media/images/facebook.svg');
    background-size: 8px 16px;
}

.social-share__link--twitter {
    background-image: url('../media/images/twitter.svg');
    background-size: 16px 15px;
}

.social-share__link--email {
    background-image: url('../media/images/email.svg');
    background-size: 20px 14px;
}
